<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-row>
      <b-col
        v-for="(campaign, key) in campaigns"
        :key="key"
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div
              class="d-flex  align-items-center justify-content-between"
            >
              <div
                class="d-flex  justify-content-center  flex-column"
                style="gap:8px;"
              >
                <h5>{{ campaign.campaign_name }} <span class="fontsize-sm"> ({{ campaign.disposition_type.type_name }} / {{ campaign.disposition.disposition_name }}) </span> </h5>
                <h5 class=" m-0 ">
                  <span class="text-success"> {{ campaign.campaign_count }} / {{ campaign.target }} </span>  <span class="fontsize-sm"> {{ campaign.campaign_percentage }}</span>
                </h5>
                <p>From: {{ campaign.date_from }} To: {{ campaign.date_to }} </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SupervisorDashboardService } from '@/services'

export default {
  name: 'SupervisorDashboard',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      campaigns: []
    }
  },

  mounted () {
    core.index()
    this.getCampaigns()
  },

  methods: {
    async getCampaigns () {
      await SupervisorDashboardService.getCampaigns().then(({ data }) => {
        this.campaigns = data
      })
    },

    refreshStatistics () {
      this.$refs.table.refresh()
    }
  }
}
</script>
